import React from 'react';
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { graphql } from 'gatsby';
import { Global, jsx, css } from '@emotion/react';
import Img from 'gatsby-image';

import SEO from '../components/seo';

const IndexPage = ({ data }) => {
  return (
    <>
      <Global
        styles={css`
          body {
            background: #000;
            min-height: 90vh;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
          }
          @keyframes slideInDown {
            from {
              transform: translate3d(0, -30px, 0);
              visibility: visible;
            }
            to {
              transform: translate3d(0, 0, 0);
            }
          }
          @keyframes fadeIn {
            0% {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
          @keyframes glow {
            0% {
              text-shadow: 0;
            }
            50% {
              // text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
              text-shadow: #fff 1px 0 20px;
              ;
            }
            to {
              text-shadow: 0;
            }
          }
        `}
      />
      <SEO 
        title={data.site.siteMetadata.title}
        description={data.site.siteMetadata.title}
        imageUrl={`${data.site.siteMetadata.siteUrl}${data.logo?.childrenImageSharp[0].fluid.src}`}
        isIndex
      />
      <div
        css={css`
          display: flex;
          flex-direction: column-reverse;
          justify-content: center;
          align-items: center;
          align-content: center;
        `}
      >
        <h1
          css={css`
            color: #fff;
            // sr-only
            position:absolute;
            left:-10000px;
            top:auto;
            width:1px;
            height:1px;
            overflow:hidden;
          `}
        >
          {data.site.siteMetadata.title}
        </h1>
        <div
          css={css`
            width: 75vw;
            max-width: 500px;
            // Desktop screens
              @media (min-width: 800px) {
                width: 50vw;
              }
            // Fade in
            animation-duration: 0.5s;
            animation-fill-mode: both;
            animation-name: fadeIn;
          `}
        >
          <Img
            fluid={data.logo?.childrenImageSharp[0].fluid}
            alt={`${data.site.siteMetadata.title} Logo`}
          />
        </div>
      </div>
      <ul
        css={css`
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          // Desktop screens
          @media (min-width: 800px) {
            flex-direction: row;
          }

          // Animate in
          animation-duration: 0.5s;
          animation-fill-mode: both;
          animation-name: slideInDown;
        `}
      >
      </ul>
      <div
        css={css`
          color: #fff;
          font-family: 'Alegreya Sans SC', sans-serif;
          display: flex;
          justify-content: center;
          margin: 25px;
        `}
      >
        © {new Date().getFullYear()} {data.site.siteMetadata.title}
      </div>
    </>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    logo: file(relativePath: {eq: "campfire_amigos_logo.jpeg"}) {
      childrenImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default IndexPage;
