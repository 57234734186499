import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title, siteUrl, isIndex, imageUrl }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );
  
  const metaDescription = description || site.siteMetadata.description;
  
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={isIndex ? null : `%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:site_name',
          content: title,
        },
        {
          property: 'og:url',
          content: site.siteMetadata.siteUrl,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: imageUrl,
        },
        {
          property: 'og:image:secure_url',
          content: imageUrl,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:image',
          content: imageUrl,
        },
        {
          property: 'twitter:url',
          content: site.siteMetadata.siteUrl,
        },
        {
          name: 'twitter:twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'author',
          content: site.siteMetadata.author,
        },
        {
          name: 'subject',
          content: 'Photography',
        },
        {
          name: 'keywords',
          content: 'Photography',
        },
        {
          name: 'copyright',
          content: title,
        },
        {
          name: 'designer',
          content: title,
        },
        {
          name: 'publisher',
          content: title,
        },
        {
          name: 'distribution',
          content: 'Global',
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
  isIndex: false,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  isIndex: PropTypes.bool,
  imageUrl: PropTypes.string,
};

export default SEO;